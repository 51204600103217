import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getOpenSiteSettings, getSiteSettingsCache, getSiteSettings } from "src/actions/site";
import ScrollToTop from "src/components/Shared/ScrollToTop";
import { ToastContainer } from "react-toastify";
import Layout from "src/layouts/Layout";
import routes from "src/routes/routes";
import { logout } from "src/actions/auth";
import "react-tooltip/dist/react-tooltip.css";

window.Buffer = window.Buffer || require("buffer").Buffer;

const App = ({ logout, ...props }) => {
  const { site, workspaceDetails, me } = props;

  const loadSettings = async () => {
    const ac = new AbortController();
    try {
      if (!Object.keys(workspaceDetails).length) {
        let data;
        if (me?._id) {
          data = await props.getSiteSettings({}, ac.signal);
        } else {
          data = await props.getOpenSiteSettings({}, ac.signal);
        }
        document.documentElement.style.setProperty("--highlightColor", data.highlight_color, "important");
        document.documentElement.style.setProperty("--headerColor", data.top_bar_color, "important");
        document.documentElement.style.setProperty("--headerTextColor", data.top_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavColor", data.side_bar_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveColor", data.side_bar_shade_color, "important");
        document.documentElement.style.setProperty("--leftNavTextColor", data.side_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveTextColor", data.left_nav_active_text_color, "important");
        document.title = data.site_name;
        if (data.image_favicon) {
          document.querySelector("link[rel*='icon']").href = `${data.image_favicon}?${Date.now()}`;
        } else {
          document.querySelector("link[rel*='icon']").href = "./assets/img/image_favicon-default.png";
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    // props.getSiteSettingsCache();
    loadSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  useEffect(() => {
    if (Object.keys(site).length || Object.keys(workspaceDetails).length) {
      if (me?.type === "user") {
        document.documentElement.style.setProperty("--highlightColor", Object.keys(workspaceDetails).length ? workspaceDetails.highlight_color : site.highlight_color, "important");
        document.documentElement.style.setProperty("--headerColor", Object.keys(workspaceDetails).length ? workspaceDetails.top_bar_color : site.top_bar_color, "important");
        document.documentElement.style.setProperty("--headerTextColor", Object.keys(workspaceDetails).length ? workspaceDetails.top_bar_text_color : site.top_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavColor", Object.keys(workspaceDetails).length ? workspaceDetails.side_bar_color : site.side_bar_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveColor", Object.keys(workspaceDetails).length ? workspaceDetails.side_bar_shade_color : site.side_bar_shade_color, "important");
        document.documentElement.style.setProperty("--leftNavTextColor", Object.keys(workspaceDetails).length ? workspaceDetails.side_bar_text_color : site.side_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveTextColor", Object.keys(workspaceDetails).length ? workspaceDetails.left_nav_active_text_color : site.left_nav_active_text_color, "important");
        document.documentElement.style.setProperty("--backgroundColor", Object.keys(workspaceDetails).length ? workspaceDetails.background_color : site.background_color, "important");
        document.title = Object.keys(workspaceDetails).length ? workspaceDetails.name : site.site_name;
        if (site.image_favicon || workspaceDetails.image_favicon) {
          document.querySelector("link[rel*='icon']").href = Object.keys(workspaceDetails).length ? `${workspaceDetails.image_favicon}?${Date.now()}` : `${site.image_favicon}?${Date.now()}`;
        }
      } else {
        document.documentElement.style.setProperty("--highlightColor", site.highlight_color, "important");
        document.documentElement.style.setProperty("--headerColor", site.top_bar_color, "important");
        document.documentElement.style.setProperty("--headerTextColor", site.top_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavColor", site.side_bar_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveColor", site.side_bar_shade_color, "important");
        document.documentElement.style.setProperty("--leftNavTextColor", site.side_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveTextColor", site.left_nav_active_text_color, "important");
        document.title = site.site_name;
        if (site.image_favicon) {
          document.querySelector("link[rel*='icon']").href = `${site.image_favicon}?${Date.now()}`;
        }
      }
    }
  }, [site, workspaceDetails, me]);

  /* useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        logout(site);
      }, 15 * 60 * 1000); // Convert minutes to milliseconds
    };

    if (site?._id && me?._id) {
      resetTimer();
    }

    // Attach event listeners to reset the timer on user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ site?._id, me?._id, logout ]) */

  return (
    <BrowserRouter>
      <div className="h-screen">
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={900}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Layout props={props}>{routes}</Layout>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getOpenSiteSettings, getSiteSettingsCache, getSiteSettings, logout })(App);
