import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import { baseUrl, noFavicon } from "src/config/host";
import TableRow from "src/components/Shared/Table/TableRow";
import { apiRequest } from "src/async/apiUtils";
import { WindowIcon, UserIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const WorkspaceEntry = ({ workspace }) => {
  const navigate = useNavigate();

  const [pages, setPages] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (workspace?._id && workspace?.workspace_type === "JWT_FULL_EMBED") {
      const loadPages = async () => {
        const res = await apiRequest("get", `/workspaces/${workspace?._id}/domo-pages`);
        if (res.status === 200) {
          setPages(res.data.data || []);
        }
      };

      const loadUserGroups = async () => {
        const res = await apiRequest("post", `/workspaces/${workspace?._id}/user-groups`, {
          body: {
            workspace_id: workspace?._id,
          },
        });
        if (res.status === 200) {
          setGroups(res.data.data?.groups || []);
        }
      };

      loadPages();
      loadUserGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?._id]);

  return (
    <TableRow keyId={workspace?._id}>
      <TRBody className="whitespace-nowrap">
        <div className="w-full flex gap-x-3 items-center relative">
          <Link
            to={`/workspaces/${workspace._id}`}
            className="hover:shadow border border-transparent hover:border-gray-100 p-2 pr-5 rounded-md text-gray-900 transition-all duration-200 font-medium cursor-pointer group">
            <div className="flex gap-4 items-center">
              <div
                style={{ backgroundColor: workspace.top_bar_color }}
                className="w-12 h-12 overflow-hidden rounded">
                {/* <img
                  src={workspace.image_favicon?.includes(".s3.") ? workspace.image_favicon + "?" + Date.now() : workspace.image_favicon?.includes("data:image/") ? workspace.image_favicon : baseUrl + workspace.image_favicon}
                  alt="Logo"
                  className="w-full h-full object-contain"
                  onError={(e) => (e.target.src = `${noFavicon}`)}
                /> */}
                <img
                  src={workspace.image_logo?.includes(".s3.") ? workspace.image_logo + "?" + Date.now() : workspace.image_logo?.includes("data:image/") ? workspace.image_logo : baseUrl + workspace.image_logo}
                  alt="Logo"
                  className="w-full h-full object-contain"
                  onError={(e) => (e.target.src = `${noFavicon}`)}
                />
              </div>
              <p className="w-[100px] sm:w-auto truncate sm:overflow-visible sm:max-w-auto h-full flex items-center gap-x-4">{workspace?.name}</p>
            </div>
          </Link>
          {workspace?.workspace_type === "JWT_FULL_EMBED" && (
            <div className="absolute -top-2 left-10 flex items-center gap-x-3 rounded-md border border-gray-200 bg-white p-[2px] text-gray-500 text-xs font-medium">
              <div className="w-6 h-6 overflow-hidden">
                <img
                  className="w-full h-full object-cover"
                  src="https://www.domo.com/assets/images/logo@2x.png"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-1 pr-1 sm:pr-3 text-sm sm:pl-6">
        <Link to={`/workspaces/${workspace?._id}?tab=Pages`}>
          <div className={classNames("font-medium w-12 flex gap-x-1 hover:underline cursor-pointer transition-all duration-100", (workspace?.workspace_type === "JWT_FULL_EMBED" ? pages?.length > 0 : workspace?.pages?.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <WindowIcon className="h-5 w-5" />
            {workspace?.workspace_type !== "JWT_FULL_EMBED" ? workspace.pages?.length || 0 : workspace.workspace_type && workspace?.workspace_type === "JWT_FULL_EMBED" ? pages.length || 0 : 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-1 pr-1 sm:pr-3 text-sm sm:pl-6">
        <Link to={`/workspaces/${workspace?._id}?tab=Groups`}>
          <div className={classNames("font-medium w-12 flex gap-x-1 hover:underline cursor-pointer transition-all duration-100", (workspace?.workspace_type === "JWT_FULL_EMBED" ? groups?.length > 0 : workspace?.groups?.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <ClipboardDocumentListIcon className="h-5 w-5" />
            {workspace?.workspace_type !== "JWT_FULL_EMBED" ? workspace.groups?.length || 0 : workspace.workspace_type && workspace?.workspace_type === "JWT_FULL_EMBED" ? groups.length || 0 : 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-1 pr-1 sm:pr-3 text-sm sm:pl-6">
        <Link to={`/workspaces/${workspace?._id}?tab=Users`}>
          <div className={classNames("font-medium w-12 flex gap-x-1 hover:underline cursor-pointer transition-all duration-100", workspace?.userIds?.length > 0 ? "text-gray-900" : "text-gray-300")}>
            <UserIcon className="h-5 w-5" />
            {workspace?.userIds?.length || 0}
          </div>
        </Link>
      </TRBody>
      {/* <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/workspaces/${workspace._id}`);
                },
              },
            ]}
          />
        </div>
      </td> */}
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceEntry);
