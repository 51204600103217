import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getWorkspaceData } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import WorkspaceEntry from "src/components/Workspaces/WorkspaceEntry";
import WorkspacesAddModal from "src/components/Workspaces/WorkspacesModals/WorkspacesAddModal";
import useFetch from "src/hooks/useFetch";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";

const WorkspaceList = ({ user, viewOnly = false }) => {
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [showAddModal, setShowAddModal] = useState(false);
  const [workspaceType, setWorkspaceType] = useState("");

  const {
    response: { data: workspaces, meta },
    status: { done: workspacesLoaded },
    refreshData: refreshWorkspaces,
  } = useFetch(`/workspaces/list`, { method: "post", query: {}, data: { includes: ["groups", "users", "pages"], limit: limit || 20, offset: offset || 0, keyword: keyword || "" } });

  useEffect(() => {
    refreshWorkspaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, keyword]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
          />
        }
        rightContent={
          workspacesLoaded && (
            <Button
              version="secondary"
              onClick={() => {
                setWorkspaceType("IFRAME_EMBED");
                setShowAddModal(true);
              }}>
              <IconsWithPlus
                strokeColor={"stroke-highlightColor"}
                item={{ icon: RectangleStackIcon }}
              />
            </Button>
          )
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Pages
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Groups
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Users
            </th>
            {/* <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th> */}
          </TRHeader>
        }
        colSpan="5"
        loaded={workspacesLoaded}
        dataExists={workspaces.length > 0}>
        {workspaces.map((workspace) => (
          <WorkspaceEntry
            viewOnly={viewOnly}
            key={workspace._id}
            workspace={workspace}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="Workspace record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <WorkspacesAddModal
        isOpen={showAddModal}
        workspaceType={workspaceType}
        onCancel={() => setShowAddModal(false)}
        onSuccess={() => {
          setShowAddModal(false);
          refreshWorkspaces();
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getWorkspaceData })(WorkspaceList);
